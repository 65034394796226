import React from "react";
import { useState } from 'react';
import { MDBBox, MDBRow, MDBCol } from 'mdbreact';
import Lightbox from "react-image-lightbox";
import "../assets/css/lightbox.css";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import LazyLoad from 'react-lazyload';


const styles = {
  images: {
    img: {
      padding: '5px',
      width: '100%'
    },
    col: {
      padding: 0,
    }
  }
};

const Gallery = (props) => {

  const [photoIndex, setPhotoIndex ] = useState(0);
  const [isOpen, setIsOpen ] = useState(false);

  const handleLightBox = (key) => {
    setPhotoIndex(key);
    setIsOpen(true);
  }

  var images = props.record.images;



  if(images.length > 0){

    return (
      <MDBBox className="pb-5">
        <div className="mdb-lightbox">
          <ResponsiveMasonry columnsCountBreakPoints={{350: 2, 750: 2, 900: 3}}>
              <Masonry>
                {
                images.map((item, key) => {

                  const image = <div style={styles.images.col}>
                    <img
                    src={item.thumb}
                    alt={`${item.title} - Emmanuelle Chenais Photo`}
                    style={styles.images.img}
                    onClick={() => handleLightBox(key)}
                    loading="lazy"
                    />
                  </div>

                  return key > 10 ? <LazyLoad key={key} height={item.preview.height} offset={100}>{image}</LazyLoad> : <div key={key}>{image}</div>;

                })}
              </Masonry>
            </ResponsiveMasonry>
        </div>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]['path']}
            nextSrc={images[(photoIndex + 1) % images.length]['path']}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]['path']}
            imageTitle={photoIndex + 1 + "/" + images.length}
            imagePadding={10}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length) }
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          />
        )}
      </MDBBox>
    );

  } else {
    return('');
  }
}

export default Gallery;
