import React, { useEffect } from "react";
import { MDBContainer } from "mdbreact";
import { BrowserRouter as Router, useLocation, Routes, Route } from 'react-router-dom';

import Home from './pages/Home';
import Album from './pages/Album';
import Socials from './components/Socials';


function usePageViews() {

  const location = useLocation();

  useEffect(() => {

    window.scrollTo({ top: 0 });

    /* Analytics */
    window._paq.push(['setCustomUrl', location.pathname]);
    window._paq.push(['deleteCustomVariables', 'page']);
    setTimeout(function(){ window._paq.push(['trackPageView']) }, 500);

  }, [location]);
}


function Footer(){

  return(
    <footer className="d-flex flex-column justify-content-center text-center py-5">
      <div>
        <h3>Emmanuelle Chenais</h3>
        <Socials />
      </div>
      <span>
        All right reserved &#169; <strong>Emmanuelle Chenais</strong> 2022
      </span>
      <span className="text-small">
        Powered by <a href="https://acte.ltd" target="_blank"><strong>ACTE Technology</strong></a>
      </span>
    </footer>
  );

}

const Switcher = (props) => {

  usePageViews();

  return(
    <Routes>
      <Route exact path="/albums/:slug" element={<Album/>} />
      <Route path="/" element={<Home/>} />
    </Routes>
  );

}


const App = (props) => {


  return (
    <Router>
      <MDBContainer fluid id="page">
          <Switcher />
      </MDBContainer>
      <Footer />
    </Router>
  );
}

export default App;
