import React, { useEffect, useState} from "react";
import Galleries from '../components/Galleries';
import { Helmet } from 'react-helmet';
import { baseUrl } from '../constant.js';
import logo from '../assets/images/logo-white.png';

const Home = () => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [records, setRecords] = useState(null);

  const fetchRecords = () => {

    console.log('Fetching galleries...');
    
    setLoading(true);

    fetch(baseUrl+"/api/galleries")
      .then(res => res.json())
      .then(
        (result) => {
          setLoading(false);
          setRecords(result);
          setTimeout(() => { window.prerenderReady = true; }, 250);
        },
        (error) => {
          setLoading(false);
          setError(true);
          console.error(error);
        }
      )
  }

  useEffect(() => {
    fetchRecords();
  }, []);


  const title = "Emmanuelle Chenais";
  const description = "Fashion, Celebrity, Portrait, Kids Photography";

  return(
    <div>

    <Helmet>
          <title>{title}</title>
          <meta name="description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={title}/>
          <meta property="og:description" content={description} />
          {records && records.length > 0 &&
            <meta property="og:image" content={records[0].image.thumb} />
          }
    </Helmet>

    <header>
      <h1>{title}</h1>
    </header>

    {!loading && records && records.length > 0 && <Galleries records={records} />}

    {loading && <p className="text-center">Loading...</p>}
    {error && <p>Oups something went wrong!</p>}

    </div>
  );

}


export default Home;
