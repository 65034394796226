import React from "react";
import fb from '../assets/images/facebook.svg';
import insta from '../assets/images/instagram.svg';


const Socials = () => {
  return(
    <div>
      <a href="https://www.facebook.com/profile.php?id=100083628364857" target="_blank" rel="noreferrer"><img className="social-icon" src={fb} width={40}/></a>
      <a href="https://www.instagram.com/emmanuellechenais" target="_blank" rel="noreferrer"><img className="social-icon" src={insta} width={40}/></a>
    </div>
  );
}

export default Socials;
